import React, { useState } from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import * as emailjs from 'emailjs-com'

const ContactForm = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [message, setMessage] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        emailjs.sendForm('service_9g2p4kq', 'template_1r56qkk', e.target,'user_T8tNgkOh8wwQ1XsOh2oU7')
      .then((result) => {
          setEmail('');
          setName('');
          setPhoneNo('');
          setMessage('');
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
     }
    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Get in Touch
                    </span>
                    <h2>Ready to Get Started?</h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" value={name} name="name" className="form-control" required placeholder="Your name" onChange={e => setName(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" value={email} name="email" className="form-control" required placeholder="Your email address" onChange={e => setEmail(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" value={phoneNo} name="phone_number" className="form-control" required placeholder="Your phone number" onChange={e => setPhoneNo(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea value={message} name="message" className="form-control" cols="30" rows="6" required placeholder="Write your message..." onChange={e => setMessage(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i> 
                                            Send Message <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm